import { baseUrl } from "../../theme/appConstants";

export const getImage= (async (item,customer="products")=>{
  console.log(item)
    let imgName = item?.replace(`public\\temp\\`, "");

    const response = await fetch(`${baseUrl}admin/send-file?imageName=${imgName}`, {
      method: 'GET',
      headers: {
        'customer': customer, // Example of a custom header
    },
    });

    const fileBlob = await response.blob();
    return  URL.createObjectURL(fileBlob);
})
