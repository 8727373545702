 export const baseUrl = 'https://backend.familyvibes.in/v1/api/'
// export const baseUrl = 'https://api.familyvibes.in/v1/api/'
// export const baseUrl = "http://localhost:8000/v1/api/";
// export const baseUrl = "https://backend.archeyewindoors.com/v1/api/";
export const imgUrl="https://backend.familyvibes.in/uploads"
export const redux = {
  /*Reducer constants*/
  IS_LOADING: "IS_LOADING",
  SHOW_CHECKOUT: "SHOW_CHECKOUT",
  SHOW_LOGIN: "SHOW_LOGIN",
  SHOW_FAQ: "SHOW_FAQ",
  SHOW_CONTACT_US: "SHOW_CONTACT_US",
  SHOW_OTP: "SHOW_OTP",
  USER_DATA_UPDATE: "USER_DATA_UPDATE",
  GIFT_CARD_DATA_UPDATE: "GIFT_CARD_DATA_UPDATE",
  WEBSITE_DATA_UPDATE: "WEBSITE_DATA_UPDATE",
  ADDRESS_DATA_UPDATE: "ADDRESS_DATA_UPDATE",
  PRICE_DATA_UPDATE: "PRICE_DATA_UPDATE",
  ORDER_LIST_DATA_UPDATE: "ORDER_LIST_DATA_UPDATE",
  GIFT_LIST_DATA_UPDATE: "GIFT_LIST_DATA_UPDATE",
  IS_VERIFIED: "IS_VERIFIED",
  INIT_PAYMENT_DATA_UPDATE: "INIT_PAYMENT_DATA_UPDATE",
  INIT_GIFTCARD_PAYMENT_DATA_UPDATE: "INIT_GIFTCARD_PAYMENT_DATA_UPDATE",
  LOGOUT: "LOGOUT",
  DOWNLOAD_INVOICE_SECTION: "DOWNLOAD_INVOICE_SECTION",
  /*Api constants*/
  API_LOGIN_SAGA: "API_LOGIN_SAGA",
  API_GIFT_CARD_SAGA: "API_GIFT_CARD_SAGA",
  API_BUY_GIFT_CARD_SAGA: "API_BUY_GIFT_CARD_SAGA",
  API_UPDATE_ADDRESS_SAGA: "API_UPDATE_ADDRESS_SAGA",
  API_CHECK_PRICE_SAGA: "API_CHECK_PRICE_SAGA",
  API_ORDER_LIST_SAGA: "API_ORDER_LIST_SAGA",
  API_GIFT_LIST_SAGA: "API_GIFT_LIST_SAGA",
  API_CONTACT_US: "API_CONTACT_US",
  API_SEND_OTP: "API_SEND_OTP",
  API_VERIFY_OTP: "API_VERIFY_OTP",
  API_LOGIN_PHONE: "API_LOGIN_PHONE",
  API_VERIFY_PHONE: "API_VERIFY_PHONE",
  API_UPDATE_USER: "API_UPDATE_USER",
};
