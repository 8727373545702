export const appImages = {
    FirstOptionIcon: require("../assets/images/free.png"),
    SecondOptionIcon: require("../assets/images/wall.png"),
    ThirdOptionIcon: require("../assets/images/satis.png"),
    PlanIcon: require("../assets/images/plan.jpg"),
    WipeIcon: require("../assets/images/wipe.jpg"),
    PressIcon: require("../assets/images/press.jpg"),
    KeepIcon: require("../assets/images/keep.jpg"),
    EvenIcon: require("../assets/images/even.jpg"),
    TagIcon: require("../assets/images/tag.png"),
    TextIcon: require("../assets/images/text.png"),
    GiftIcon: require("../assets/images/gift.png"),
    TickIcon: require("../assets/images/tick.png"),
    GoogleIcon: require("../assets/images/google.png"),
    FacebookIcon: require("../assets/images/f.png"),
    FramIcon: require("../assets/images/fram.png"),
    ClockIcon: require("../assets/images/clock.png"),
    PercentIcon: require("../assets/images/percent.png"),
    QuesIcon: require("../assets/images/ques.png"),
    ChattIcon: require("../assets/images/chatt.png"),
    HandIcon: require("../assets/images/hand.png"),
    HomeIcon: require("../assets/images/home.png"),
    ChatIcon: require("../assets/images/chat.png"),
    QuestionIcon: require("../assets/images/question.png"),
    AmericaIcon: require("../assets/images/america.svg"),
    EspIcon: require("../assets/images/esp.svg"),
    FranIcon: require("../assets/images/fran.svg"),
    DeutIcon: require("../assets/images/deut.svg"),
    ItalIcon: require("../assets/images/ital.svg"),

    CameraIcon: require("../assets/images/camera.png"),
    FBIcon: require("../assets/images/facebook-icon.png"),
    InstaIcon: require("../assets/images/instagram-icon.png"),
    CheckOutIcon: require("../assets/images/checkout.png"),
}